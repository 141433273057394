<template>
    <div>
        <div id="home" class="trading-background d-flex flex-column justify-content-center" style="min-height: 150vh;">
            <div id="first-container" class="mb-5 mx-auto d-flex flex-md-column flex-column position-relative">
                <div id="TatSing-trading" class="my-0 py-0 py-md-3 my-md-3 container w-100" style="z-index: 1;">
                    <div class="col-6 my-0 my-md-5"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true">
                        <img class="mt-5" src="../assets/img/tatsing-global-trading-logo.png" alt="TatSing-trading-Logo">

                        <p class="fs-2 lh-sm my-5">Feeling good about <br> making a <strong class="text-theme">difference</strong>.</p>

                        <a href="#trade-to-us" class="text-decoration-none"><button type="button" class="text-white p-3 rounded d-flex align-items-center gap-3 bg-dark border-0 justify-content-center" style="width: 160px;">Read More <img src="../assets/img/icons/arrow-down.png" alt="arrow-down" height="16"></button></a>
                    </div>
                </div>
                <div id="graph-img" class="ps-0 ps-md-4 pt-5 mt-5 w-75 position-absolute h-100 end-0"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true">
                    <img src="../assets/img/Trading-PNG-Photo.png" alt="graph-img" class="h-100 w-100 mt-5">
                </div>
            </div>
            

            <div id="trade-to-us" class="container p-4 h-100 mt-5">
                <div class="mt-5 d-flex flex-column gap-5">
                    <h1 class="fw-bold text-center fs-1 w-50 m-auto text-white"
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true">Trade smoothly and easily with <br> <span class="text-yellow">Tat Sing</span>!</h1>
                    <div class="d-flex justify-content-evenly justify-content-md-evenly justify-content-sm-between text-center counter">
                        <div class="row py-5 gap-5 text-center text-lg-start">
                            <div class="col-12 col-sm-12 col-md mx-auto"
                                data-aos="zoom-in"
                                data-aos-offset="100"
                                data-aos-duration="1000"
                                data-aos-easing="ease-in-out"
                                data-aos-mirror="true"
                                data-aos-once="true">
                                <img src="../assets/img/icons/Sourcing-and-Procurement.svg" alt="sourcing-procurement" class="m-auto me-lg-auto">
                                <div>
                                    <h1 class="fw-bold mt-3 mb-4 fs-2 text-white">Sourcing and Procurement</h1>
                                    <p class="text-muted">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse quam id quasi unde iure! Optio!</p>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md mx-auto"
                            data-aos="zoom-in"
                                data-aos-offset="100"
                                data-aos-duration="1500"
                                data-aos-easing="ease-in-out"
                                data-aos-mirror="true"
                                data-aos-once="true">
                                <img src="../assets/img/icons/Indent-Orders-and-Wholesale.svg" alt="indent-orders-wholesale">
                                <div>
                                    <h1 class="fw-bold mt-3 mb-4 fs-2 text-white">Indent Orders <br> and Wholesale</h1>
                                    <p class="text-muted">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse quam id quasi unde iure! Optio!</p>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md mx-auto"
                            data-aos="zoom-in"
                                data-aos-offset="100"
                                data-aos-duration="2000"
                                data-aos-easing="ease-in-out"
                                data-aos-mirror="true"
                                data-aos-once="true">
                                <img src="../assets/img/icons/Import-and-Export.svg" alt="import-export">
                                <div>
                                    <h1 class="fw-bold mt-3 mb-4 fs-2 text-white">Import and <br> Export</h1>
                                    <p class="text-muted">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse quam id quasi unde iure! Optio!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="projects" class="bg-light p-5 shadow">
            <div class="container d-flex flex-column gap-5">
                <h1 class="fw-bold text-center fs-1 w-50 m-auto">We don't only deliver. We deliver with care.</h1>
                <div class="d-flex justify-content-evenly justify-content-md-evenly justify-content-sm-between text-center counter">
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-center">
                            <span class="fs-3 fw-bold text-theme datacounter" data-counter="300"></span>
                            <span class="fs-3 fw-bold text-theme" v-show="isCounting">+</span>
                        </div>
                        <span class="text-gray">Serviced Clients</span>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="fs-3 fw-bold text-theme datacounter" data-counter="1012"></span>
                        <span class="text-gray">Completed Projects</span>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-center">
                            <span class="fs-3 fw-bold text-theme datacounter" data-counter="300"></span>
                            <span class="fs-3 fw-bold text-theme" v-show="isCounting">+</span>
                        </div>
                        <span class="text-gray">On-going Projects</span>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-center">
                            <span class="fs-3 fw-bold text-theme datacounter" data-counter="861"></span>
                            <span class="fs-3 fw-bold text-theme" v-show="isCounting">M</span>
                        </div>
                        <span class="text-gray">Total Income</span>
                    </div>
                </div>
            </div>
        </div>

        <div id="services" class="bg-light py-5 overflow-hidden" style="min-height: 100vh;">
            <div class="container py-5 mt-5">
                <div class="m-auto position-relative w-100 d-flex flex-column">
                    <span class="fs-3 fw-bold text-uppercase px-1 text-theme">Services</span>
                    <span class="position-absolute h-100 border-bottom border-5 left-0 my-1 border-secondary-theme underline"></span>
                </div>

                <div class="d-flex flex-lg-row gap-4 flex-column position-relative h-100 w-100">
                    <div class="w-50"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true">
                        <div class="d-flex flex-column gap-0 gap-md-5 h-100 position-relative">
                            <h1 class="display-4 fw-bold mt-4 mb-5">What does Tat Sing Consulting do?</h1>
                            <div class="w-100 d-flex flex-column gap-3">
                                <h2 class="text-dark fw-bold fs-large">Better Risk Management</h2>
                                <h2 class="text-muted fw-bold fs-large">Faster Technological Process</h2>
                                <h2 class="text-muted fw-bold fs-large">Improve Business Productivity</h2>
                            </div>
                        </div>
                    </div>

                    <div class="w-50" 
                    data-aos="fade-left"
                    data-aos-offset="100"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true">
                        <div class="d-flex flex-column gap-3 h-100 position-relative">
                            <img src="../assets/img/icons/Services-illust.svg" alt="data-graph" class="w-75 mx-auto">
                            <h2 class="fs-large">Driving Aftermarket Value</h2>
                            <p class="text-muted" style="font-size: medium;">Tat Sing Global Trading Corporation (“TS Global”) is an importer and wholesaler of components and spare parts for commercial vans, trucks, and trailers. As a global trading company, TS Global continue expanding its business supply chain network around the world to effectively source quality and affordable aftermarket products that satisfy customer needs. Since 2018, TS Global place great focus on aftermarket value chain and continuously provide its customers with more than 100 SKUs ranging from wing van hydraulic power unit, commercial tires, wheel rims, mud flap, twist lock, landing gear, ratchet, stud bolts, latches, hinges, and many more. Our primary motivation is to cater the needs of our road-based transport and logistics sectors nationwide. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="testimonials" class="bg-light vh-100 d-flex flex-column position-relative overflow-hidden">
            <div class="container w-100">
                <div class="col-6 my-5 my-xl-4 px-0"
                    data-aos="fade-right"
                    data-aos-offset="100"
                    data-aos-duration="1500"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true">
                    <div class="m-auto position-relative w-100 d-flex flex-column">
                        <span class="fs-3 fw-bold text-uppercase px-1 text-theme">Testimonials</span>
                        <span class="position-absolute h-100 border-bottom border-5 left-0 my-1 border-secondary-theme underline"></span>
                    </div>

                    <div class="m-auto d-flex flex-column h-100 position-relative">
                        <h1 class="display-4 fw-bold mt-4 mb-5">We trust what our clients say</h1>
                        <div class="w-100">
                            <div>
                                <div class="testimonials-slider swiper position-relative p-0 m-0">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide">
                                            <div class="testimonial-item w-75">
                                                <div class="py-3 w-100 h-100 d-flex gap-2 align-items-center">
                                                    <img src="../assets/img/Ellipse-202.png" alt="client-pic" width="100" height="100">
                                                    <div class="d-flex flex-column gap-2 w-100 h-100" style="font-size: larger;">
                                                        <span class="fw-bold">Isabella Swan</span>
                                                        <span>TSG Employee</span>
                                                    </div>
                                                </div>
                                                <p style="font-size: large;">
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora sit minus doloremque quasi exercitationem similique?
                                                </p>
                                            </div>
                                        </div>
                                        <div class="swiper-slide">
                                            <div class="testimonial-item w-75">
                                                <div class="py-3 w-100 h-100 d-flex gap-2 align-items-center">
                                                    <img src="../assets/img/Ellipse-202.png" alt="client-pic" width="100" height="100">
                                                    <div class="d-flex flex-column gap-2 w-100 h-100" style="font-size: larger;">
                                                        <span class="fw-bold">Isabella Swan</span>
                                                        <span>TSG Employee</span>
                                                    </div>
                                                </div>
                                                <p style="font-size: large;">
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora sit minus doloremque quasi exercitationem similique?
                                                </p>
                                            </div>
                                        </div>
                                        <div class="swiper-slide">
                                            <div class="testimonial-item w-75">
                                                <div class="py-3 w-100 h-100 d-flex gap-2 align-items-center">
                                                    <img src="../assets/img/Ellipse-202.png" alt="client-pic" width="100" height="100">
                                                    <div class="d-flex flex-column gap-2 w-100 h-100" style="font-size: larger;">
                                                        <span class="fw-bold">Isabella Swan</span>
                                                        <span>TSG Employee</span>
                                                    </div>
                                                </div>
                                                <p style="font-size: large;">
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora sit minus doloremque quasi exercitationem similique?
                                                </p>
                                            </div>
                                        </div>
                        
                                    </div>
                                    <div class="mt-3 h-25">
                                        <div class="swiper-pagination text-start text-theme bottom-0"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-absolute h-100 end-0" style="width: 45%;" 
            data-aos="fade-left"
            data-aos-offset="100"
            data-aos-duration="1500"
            data-aos-easing="ease-in-out"
            data-aos-delay="750"
            data-aos-mirror="true"
            data-aos-once="true">
                <img src="../assets/img/delivering.png" alt="deliver" class="h-100 w-100" style="object-fit: 100%;">
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "../assets/vendor/swiper/swiper-bundle.min.js"

export default {
  name: 'Home',
  data() {
    return {
      isCounting: false,
      inter: ''
    }
  },
  mounted() {
    document.addEventListener('scroll', () => {
      this.count()
    })   

   new Swiper('.testimonials-slider', {
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });
  },
  methods: {
    checkVisible(el, container, threshold, mode) {
        threshold = threshold || 0;
        mode = mode || 'visible';

        var rect = el.getBoundingClientRect();
        var viewHeight = Math.max(container, window.innerHeight);
        var above = rect.bottom - threshold < 0;
        var below = rect.top - viewHeight + threshold >= 0;
        
        return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
    },
    count() {
        if(this.$route.path == '/') {
            if(!this.isCounting) {
                const projects_id = document.getElementById('projects')
                if(this.checkVisible(projects_id, document.documentElement.clientHeight)) {
                this.isCounting = true
                const counters = document.querySelectorAll('.datacounter')
                counters.forEach((counter) => {
                    if(counter.innerText !== counter.getAttribute('data-counter')) {
                    counter.innerText = '0'
                    
                    const updateCounter = () => {
                        const end = +counter.getAttribute('data-counter')
                        const c = +counter.innerText

                        const increment = end / 200

                        if(c < end) {
                        counter.innerText = `${Math.ceil(c + increment)}`
                        requestAnimationFrame(updateCounter)
                        }
                        else {
                        counter.innerText = end
                        }
                    }
                    updateCounter()
                    }
                })
                }
            }
        }
      return
    },
    debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }
  }
}
</script>
