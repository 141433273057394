import { createStore } from 'vuex'
import axios from 'axios'

axios.defaults.baseURL = "https://tsg-api.tatsing.com.ph/api/v1"

export default createStore({
  state: {
    products: {},
    totalPages: 1,
    currentPage: 1,
    isLoading: false,
    hasCart: localStorage.getItem('cart') ? true : false,
    errors: {},
    message: ''
  },
  mutations: {
    hasCart_true(state) {
      state.hasCart = true
    },
    isLoading(state) {
      state.isLoading = true
    },
    isFinishedLoading(state) {
      state.isLoading = false
    }
  },
  actions: {
    async getProducts({state}) {
      state.isLoading = true
      await axios.get('https://fakestoreapi.com/products').then(response => {
        Object.assign(state.products, response.data)
        for(var i in response.data) {
          const title = response.data[i].title.replace(/[^a-zA-Z0-9]+/g, '-')
          state.products[i].link = title
        }
        state.isLoading = false
      }).catch(() => {
      })
    },
    async is_visiting() {
      await axios.post('/visit', { company_name: 'Global Trading' }).then(() => {
        localStorage.setItem('trad-visited', true)
      })
    },
    async send({state}, mail_data) {
      state.isLoading = true
      mail_data['company'] = 'Global Trading'
      await axios.post('/send', mail_data).then(() => {
        state.message = 'Sending was Successful'
        state.isLoading = false
      }).catch(error => {
        state.message = 'Yikes! Please try again later.';
        if(error.response.status === 422) {
          state.message = 'Yikes! '+error.response.data.message;
          if(error.response.data.errors)
          {
            state.errors = error.response.data.errors
          }
        }
        if(error.response.status === 429) {
          state.message = 'Yikes! Please wait for 5 minutes before sending again.';
        }
        state.isLoading = false
      })
    }
  },
  modules: {
  }
})
